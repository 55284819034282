<template>
  <div>
     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="primary" size="medium" plain @click="yulanFun">预览模板</el-button>
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
       </div>
     </div>
     <div class="contents">
       <div class="mainbox">
          <el-tabs>
            <el-tab-pane label="创建模板" name="first"></el-tab-pane>
          </el-tabs>
          <div class="formTable">
            <el-form ref="goodArr" :model="goodArr" label-position="right" label-width="200px">
              <el-form-item label="模板名称：">
                  <el-input v-model="goodArr.name" clearable  style="width:300px"
                    placeholder="请输入模板名称"></el-input>
                  <p style="color:#ff0000; font-size:12px; height:20px;">“开团易单号、来源单号、收件人、电话、数量、地址信息” 为系统必选项，已自动置入</p>
                </el-form-item>
                <template v-for="itemes in sortField">
              <el-form-item :label="itemes.category + '：'">
                <!-- <el-checkbox-group v-model="goodArr.checkList" @change="changeList"> -->
                      <template v-for="item in itemes.children">
                        <el-checkbox :label="item.name" :checked="item.required" @change="changeList($event, item.name)"></el-checkbox>
                      </template>
                <!-- </el-checkbox-group> -->
              </el-form-item>
                </template>

              <el-form-item label="表格格式：" >
                <el-radio-group v-model="goodArr.splitMergeStrategy">
                  <el-radio v-for="(item, index) in dispatchStrategyOptions"
                    :key="index" :label="item.value" :disabled="item.disabled">{{item.label}}</el-radio>
                </el-radio-group>
              </el-form-item>  

              <el-form-item label="是否包含统计行：">
                <el-radio-group v-model="goodArr.enableStats">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

                <el-form-item label="是否启用：">
                <el-radio-group v-model="goodArr.disabled">
                  <el-radio :label="0">启用</el-radio>
                  <el-radio :label="1">禁止</el-radio>
                </el-radio-group>
              </el-form-item>
               <el-form-item label="模板列名设置：">
          <div class="paixunbox">
          <el-button type="primary" size="small" @click="addRow" style="margin-bottom:10px">添加自定义列名</el-button>
          <div class="paixunTable">
            <div class="paixunItem biaoticss">
              <span style="width:200px">系统列名</span>
              <span><bdo style="width:200px; display:inline-block">Excel列名称</bdo></span>
              <span>操作</span>
              <span>列名排序(从左至右)</span>
            </div>
              <draggable v-model="templateList" @update="datadragEnd">
                <transition-group>
                  <div class="paixunItem" v-for="(item, indexs) in templateList" :key="item.id">
                    <span>
                    <strong v-if="item.id">{{item.name}}</strong>
                     <el-select v-else v-model="item.name" filterable placeholder="请选择" style="width:200px" @change="handleSelect($event, indexs)">
                        <el-option
                          v-for="itmmm in FieldsData"
                          :key="itmmm.id"
                          :label="itmmm.name"
                          :value="itmmm.name"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span><el-input v-model="item.zidingyi" clearable @change="changeItem" style="width:200px" placeholder="请输入"></el-input></span>
                    <span><el-button @click="delfun(indexs)" type="text" size="small">删除</el-button></span>
                    <span><el-button class="tuoye" type="text" size="small">拖动排序</el-button></span>
                  </div>
                </transition-group>
              </draggable>
          </div>
         </div>
        </el-form-item>
             <el-form-item label="按某列顺序排序：">
               <el-checkbox-group v-model="goodArr.checkData">
                  <template v-for="item in templateList">
                    <el-checkbox :label="item.zidingyi"/>
                  </template>
               </el-checkbox-group>
             </el-form-item>
             <p class="ziduan">当前你选择的排序字段：
                <span v-for="(items, index) in goodArr.checkData">
                   {{index+1}} {{items}} >
                </span>
             </p>
            </el-form>
          </div>
        
      </div>   
    </div>

    <el-dialog title="预览导出模板" :visible.sync="mobanflag" :before-close="handleClose" width="100%">
     <el-table
        :data="mobanArr"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
       <template v-for="itemes in templateList">
        <el-table-column
          :prop="itemes.id"
          :label="itemes.zidingyi"
          header-align="center" align="center"
        >
        </el-table-column>
       </template>
     </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getFields, mobanDetail, editMoban, addMoban } from "@/api/moban";
import draggable from 'vuedraggable'
export default {
  name: 'mobanDetail',
  components: { draggable },
  data() {
    return {
      goodArr: {
        id: '',
        name: '',
        disabled: 0,
        checkList: [],
        checkData: [],
        enableStats: 1,
        splitMergeStrategy: 0,
        buildIn: 0
      },
      FieldsData: [],
      sortField: [],
      mobanflag: false,
      templateList: [],
      mobanArr: [],
      dataList:[],
      detailArr: [],
      dispatchStrategyOptions: [{
        "label": "一个订单一行",
        "value": 0
      }, {
        "label": "一件商品一行",
        "value": 1
      }],
    }
  },
  created: function () {
   
  },
  methods:{
    addfun(){
      var that = this
      var objs= {
        value: ''
      }
      that.timeArr.push(objs);
    },
    delfun(n){
      var that = this
      console.log(n)
      that.templateList.splice(n,1)
      that.goodArr.checkData = []
      that.FieldsData.map((item)=>{
        item.required = false
        that.templateList.map((itemes)=>{
          if(item.name == itemes.name){
            item.required = true
          }
        })
      })
      console.log(that.FieldsData)
      that.sortField=[]
      this.$nextTick(()=>{
        let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
        that.sortField = sortFieldArr
        console.log(that.sortField)
      })
      
      this.$forceUpdate()
    },
    changeItem(val){
      var that = this
      that.goodArr.checkData = []
    },
    yulanFun(){
      this.mobanflag = true
      var that = this
      var objs = {}
      that.mobanArr = []
      console.log(that.templateList)
      that.templateList.map((items)=>{
        objs[items.id] = items.example
      })
      that.mobanArr.push(objs)
      console.log(that.mobanArr)
    },
    handleClose(){
      this.mobanflag = false
    },
    addRow(){
      var that = this
      var zidingyi = {
        name: "", 
        zidingyi: '',
        id: '', 
        sort: ''
      }
      that.templateList.push(zidingyi)
    },
    changeList(e, name){
      console.log(e, name)
      var that = this
      that.goodArr.checkData = []
      that.FieldsData.map((item)=>{
        if(item.name == name){
          item.required = e
          if(item.required){
            item.zidingyi = item.name
            that.templateList.push(JSON.parse(JSON.stringify(item)))
          }else{
            that.templateList.map((its, index)=>{
              if(its.name == name){
                that.templateList.splice(index,1)
              }
            })
            
          }
        }
       
      })
    },
    getMobanDetail(id){
      var objs = {
        id: id
      }
      mobanDetail(objs).then(res => {
        if(res.code == 200){
          this.templateList = []
          this.goodArr.id = res.data.id
          this.goodArr.name = res.data.name
          this.goodArr.disabled = res.data.disabled
          this.goodArr.buildIn = res.data.buildIn
          this.goodArr.enableStats = res.data.enableStats
          this.goodArr.splitMergeStrategy = res.data.splitMergeStrategy
          let editFrom = res.data.content || []
          this.templateList = editFrom
          this.detailArr = editFrom
          this.dataList = res.data.sortField
          this.dataList.map((item)=>{
            this.goodArr.checkData.push(item.zidingyi)
          })
          this.getAllData()
        }
      })
    },
    getAllData(){
      var that = this
      var ids= this.$route.query.id
      var objs = {
        role: window.sessionStorage.getItem("role")
      }
      getFields(objs).then(res => {
        if(res.code ===200) {
          that.FieldsData = res.data
          if(ids){
            that.FieldsData.map((item)=>{
              item.required = false
              that.templateList.map((itemes)=>{
                if(item.name == itemes.name){
                  item.required = true
                  itemes.example = item.example
                }
              })
            })
            let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
            that.sortField = sortFieldArr
          }else{
            that.FieldsData.map((item)=>{
              if(item.required){
                item.zidingyi = item.name
                that.templateList.push(JSON.parse(JSON.stringify(item)))
              }
            })
            let sortFieldArr = that.sortArr(that.FieldsData, ['category'])
            that.sortField = sortFieldArr
          }
          
          
        }else{
          that.$message.error(res.message)
        }
      })
    },
    handleSelect(e, index){
      var that = this
      console.log(e, index)
      that.FieldsData.map((items)=>{
        if(items.name === e){
          that.templateList[index].id = items.id
          return false
        }
      })
    },
    sortArr(data, keys = []) { //keys可以传一个数组
      var c = [];
      var d = {};
      for (var element of data) {
        let element_keyStr = "";
        let element_key = [];
        let element_keyObj = {};
        for (var key of keys) {
          element_key.push(element[key]);
          element_keyObj[key] = element[key];
        }
        element_keyStr = element_key.join("_");
        if (!d[element_keyStr]) {
          c.push({
            ...element_keyObj,
            children: [element]
          });
          d[element_keyStr] = element;
        } else {
          for (var ele of c) {
            let isTrue = keys.some(key => {
              return ele[key] != element[key];
            });
            if (!isTrue) {
              ele.children.push(element);
            }
          }
        }
      }
      return c;
    },
    datadragEnd (evt) {
      var that = this 
      console.log('拖动前的索引 :' + evt.oldIndex)
      console.log('拖动后的索引 :' + evt.newIndex)
      that.templateList = that.templateList
      that.goodArr.checkData = []
    },
    submitFun(){
      var that = this
      var params = {}
      if(that.goodArr.name==''){
        that.$message.error('请输入模板名称')
        return
      }
      that.dataList = []
      console.log(that.goodArr.checkData)
      console.log(that.templateList)
     
      that.goodArr.checkData.map((items)=>{
        that.templateList.map((item)=>{
          if(item.zidingyi ===items){
            that.dataList.push(item)
          }
        })
      })
      console.log(that.dataList)
      params.disabled = that.goodArr.disabled
      params.name = that.goodArr.name
      params.sortFields = that.dataList
      params.template = that.templateList
      params.role = window.sessionStorage.getItem("role")
      params.buildIn = that.goodArr.buildIn
      params.enableStats = that.goodArr.enableStats
      params.splitMergeStrategy = that.goodArr.splitMergeStrategy

      if(that.goodArr.id){
        params.id = that.goodArr.id
        editMoban(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$router.push({
              path: "/sendmoban"
            })
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }else{
        addMoban(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.push({
              path: "/sendmoban"
            })
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }
    },
    cancalFun(){
      this.$router.go(-1)
    },
    anzufen(datas){
      var map = {}, dest = [];
      for (var i = 0; i < datas.length; i++) {
        var ai = datas[i];
        if (!map[ai.category]) {
          dest.push(ai);
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.category== ai.category) {
              dj.name.push(ai.name);
              break;
            }
          }
        }
     }
     return dest;
    }
  },
  mounted() {
  
   var ids= this.$route.query.id
   if(ids){
     this.getMobanDetail(ids)
   }else{
     this.getAllData()
   }
  
  }
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.paixunbox{
  display: block;
  overflow: hidden;
}
.paixunTable{
  overflow: hidden;
  display: block;
  border: 1px solid #eee;
  border-bottom: none;
  border-right: none;
}
.tuoye{
  cursor: move;
}
.ziduan{
  padding-left: 200px;
  font-size: 12px;
  color: #ff0000;
  overflow: hidden;
  margin-top: -10px;
  padding-bottom: 20px;
}
.paixunItem{
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  display:flex;
  color:#333;
  border-bottom: 1px solid #eee;
}
.paixunItem span{
  flex: 1;
  display: block;
  line-height:40px;
  padding: 5px 10px;
  border-right: 1px solid #eee;
}
.paixunTable .biaoticss{
  background: #f7f7f7;
  color:#999;
  font-size: 12px;
  font-weight: bold;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}

</style>